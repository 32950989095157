import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Alert,
    Table
} from 'react-bootstrap'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../../Biometric/StatesComponents/LoadingHandler'
import { Link } from 'react-router-dom'

import { utils, read, writeFile } from 'xlsx';

export function PassportCount() {

    const [loader, setLoader] = useState(false)

    const [passports, setPassports] = useState<any>({
        user: 0,
        biometry: 0
    })

    const getCount = () => {

        setLoader(true)

        fetch(`${BASE.api_biometry.base_url}${BASE.api_biometry.internal_biometry_count}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY,
              'x-api-key': BASE.api_user.api_key
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoader(false)
                // Colocar a resposta da api aqui para as 2 propriedades: done e waiting
                setPassports(response)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }


    const downloadExcel = async () => {


        const jsonData: any = []

        for (const singleUser of passports.list) {


            jsonData.push(
                {
                    torcedor: `${singleUser.firstName} ${singleUser.lastName}`,
                    cpf: singleUser.document,
                    biometria: singleUser.biometry !== 0 ? 'Sim' : 'Não',
                    newcId: singleUser.externalId,
                    email: singleUser.email
                })

        }

        const worksheet = utils.json_to_sheet(jsonData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, `passport_report.xlsx`);
    };

    useEffect(() => {
        getCount()
          setInterval(getCount, 30000)
      }, [])

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">Usuários e Biometrias</Card.Title>
            </Card.Header>
            <Card.Body>

            <Row>
            <Col sm={6}>
                    <Card.Body>
                        <div className="row d-flex align-items-center">
                        <div className="col-auto">
                            <i className="feather icon-alert-circle f-30 text-c-gray" />
                            </div>
                            <div className="col">
                            <h3 className="f-w-300">{passports.user}</h3>
                            <span className="d-block text-uppercase">Usuários Criados</span>
                        </div>
                        </div>
                    </Card.Body>
                </Col>
                <Col sm={6}>
                    <Card.Body>
                        <div className="row d-flex align-items-center">
                            <div className="col-auto">
                            <i className="feather icon-check-circle f-30 text-c-blue" />
                            </div>
                            <div className="col">
                            <h3 className="f-w-300">{passports.biometry}</h3>
                            <span className="d-block text-uppercase">Biometrias Completas</span>
                            </div>
                        </div>
                    </Card.Body>
                </Col>
      
            </Row>
        </Card.Body>
    </Card >
    )
}
